@import '../../../app.scss';

.register-page{
    font-size: 0.75rem;
    height: 100vh;
    width: 100%;
    mix-blend-mode: color-burn;
    // background-color: yellow;
    // display: none;
    background-color: #382372;
    // background-color: aliceblue;
    background-color: #03548a;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    background: linear-gradient(to right, #023b61, #026aaa);
    background: radial-gradient(circle at 100% 100%, #0265a7, #011f31);


    
}

.register-page .register-page-bg-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    // aspect-ratio: 13/2;
    // object-fit: contain;
    // mix-blend-mode: color-burn;
    // object-fit: fill;
    // object-fit: contain;
    // object-fit: scale-down;
    // object-fit: none;
    // object-fit: none;
    position: absolute;
    z-index: 1;

    // display: block;
    // // object-fit: contain;
    // max-width: 100%;
    // max-height: 100%;
    // width: auto;
    // height: auto;
    // display: none;
}

.register-page .register-page-form-container{
    width: 100%;
    height: 100%;
    // background-color: pink;
    z-index: 2;
    // position: absolute;
    //if there is an img use absolute

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-color: #382372;
    // background-color: yellow;

}

.register-page .register-page-form{
    // background-color: lime;
    height: 100%;
    width: 100%;
    // height: 80%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    // align-items: flex-end;
    // justify-content: space-around;
    // font-size: 0.85rem;

    @include media(mobile, tablet){
        justify-content: center;
    }

}

.register-page .register-page-form-inner-wrapper{
    background-color: white;
    // background-color: yellow;
    // height: 85%;
    border-radius: 1rem;
    padding: 2.5rem 1.5rem;
    margin-right: 7rem;
    width: 50%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    // background-color: #382372;

    @include media(mobile, tablet){
        // width: 100%;
        width: 90%;
        // background-color: lime;
        // background-color: orange;
        background-color: white;
        padding: 0;
        margin: 0;
        padding: 2rem 0.5rem;
        // background-color: yellow;
    }

}

.register-page .register-page-form-inner-wrapper-group-1{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.register-page .register-page-form-inner-wrapper-group-1-a{
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0
}

.register-page .register-page-form-inner-wrapper-group-1-b{
    cursor: pointer;
}

.register-page .register-page-form-inner-wrapper-group-2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.register-page .register-page-form-inner-wrapper-group-2-a{

    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.5rem;
    // background-color: white;
    height: 2.5rem;
    position: relative;
    border: 1px solid rgba(135, 134, 134, 0.5);
    // border: 1px solid rgba(135, 134, 134, 0.25);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // background-color: pink;
    border-radius: 0.2rem;


}

.register-page .register-page-form-inner-wrapper-group-2-a-icon-1{
    // position: absolute;
    bottom: 50%;
    top: 50%;
    left: 0.7rem;
    // background-color: pink;
    color: gray;
    flex: 1;
    margin-left: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.register-page .register-page-form-inner-wrapper-group-2-a-icon-2{
    // position: absolute;
    // top: 0;
    bottom: 50%;
    top: 50%;
    right: 0.7rem;
    // background-color: pink;
    color: gray;
    font-size: 1.1rem;
    // display: none;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.register-page .register-page-form-inner-wrapper-group-2-a >input{
    all: unset;
    background-color: white;
    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.3rem 2rem;
    background-color: white;
    // background-color: #382372;

    // background-color: pink;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // line-height: 2rem;
    // width: 83%;
    height: 100%;
    flex: 15;
} 


.register-page .register-page-form-inner-wrapper-group-2-b{

    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.5rem;
    // background-color: white;
    height: 2.5rem;
    position: relative;
    border: 1px solid rgb(135, 134, 134);
    border: 1px solid rgba(135, 134, 134, 0.5);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // background-color: pink;
    border-radius: 0.2rem;


}

.register-page .register-page-form-inner-wrapper-group-2-b-icon-1{
    // position: absolute;
    bottom: 50%;
    top: 50%;
    left: 0.7rem;
    color: gray;
    flex: 1;
    margin-left: 0.5rem;

    // background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // display: none;
    // line-height: 3rem;
}

.register-page .register-page-form-inner-wrapper-group-2-b-icon-2{
    // position: absolute;
    // top: 0;
    bottom: 50%;
    top: 50%;
    right: 0.7rem;
    // background-color: pink;
    color: gray;
    // display: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.1rem;

}


.register-page .register-page-form-inner-wrapper-group-2-b >input{
    all: unset;
    background-color: white;
    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.3rem 2rem;
    background-color: white;
    // background-color: #382372;

    // background-color: pink;
    // line-height: 1rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // line-height: 2rem;
    // width: 83%;
    height: 100%;
    flex: 15;
} 

.register-page .register-page-form-inner-wrapper-group-2-c{
    cursor: pointer;
}

.register-page .register-page-form-inner-wrapper-group-3{
    width: 100%;
    background-color: pink;
}

.register-page .register-page-form-inner-wrapper-group-3 > button {
    border: none;
    background-color: black;
    color: white;
    padding: 0.7rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    background-color: #382372;
    background: linear-gradient(to right, #013152, #0d75b5)

}






















.verification-mail-sent-page{
    width: 100%;
    height: 100%;
    // background-color: cyan;
    display: flex;
    justify-content: center;
}

.verification-mail-sent-page-inner{
    width: 85%;
    // background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.verification-mail-sent-page-box{
    border: 1px solid gainsboro;
    padding: 1rem;
    border-radius: 0.5rem 0rem 0.5rem 0rem;
    font-size: 0.8rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.verification-mail-sent-page-box-top{
    font-size: 3rem;
}

.verification-mail-sent-page-box-info{
    font-size: 1.1rem;
}

.verification-mail-sent-page-box-editForm{
    font-weight: 300;
    cursor: pointer;
}

.verification-mail-sent-page-box-resend{
    font-weight: 300;
    cursor: pointer;
}









