@import '../../../app.scss';

.reusable-thesis-item{
    background-color: rgba(204, 239, 255, 0.253);
    // background-color: rgb(249, 244, 244);
    // background-color: rgb(255, 252, 252);
    background-color: rgb(248, 248, 248);
    background-color: white;
    background-color: rgb(243, 251, 255);


    border: 1px solid rgb(236, 235, 235);
    // border-radius: 0.4rem;
    border-radius: 0rem 1rem 0.4rem 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.7rem;
    color: rgb(45, 45, 45);
    // background-color: yellow;
    width: 100%;
    // overflow: hidden;
    // background-color: lime;
    // color: 5, 118, 194);

    @include media(mobile, tablet){

    }
}

.reusable-thesis-item .random-thesis-suggestion-item{
    width: 95%;
    // height: 100%;
    // background-color: red;
    // background-color: lime;
    // overflow: hidden;
    // border-right: 3px solid rgba(212, 212, 212, 0.76);
    // border-bottom: 3px solid rgba(211, 211, 211, 0.425);
    // border-radius: 0.5rem;
    // overflow: hidden;

    padding: 1rem;
    // padding: 0.51rem 0rem 0rem 0rem;
    // padding: 1rem;
    // padding-top: 0.5rem;


}

.reusable-thesis-item .random-thesis-suggestion-item-title{
    // font-size: 1.5rem;
    font-weight: bold;
    font-weight: 300;
    // font-weight: 300;
    font-size: 1rem;
    color: rgb(48, 48, 48);
    margin-bottom: 0.5rem;
}

.reusable-thesis-item .random-thesis-suggestion-item-file-available{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.reusable-thesis-item .rtsifa-box-available{
    border: 1px solid rgba(3, 47, 77, 0.349);
    padding: 0rem 0.6rem;
    color: rgba(0, 128, 0, 0.759);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    color: rgb(5, 118, 194);

}

.reusable-thesis-item .rtsifa-box-pdf{
    background-color: rgba(174, 243, 174, 0.442);
    // border: 1px solid rgba(0, 128, 0, 0.322);
    padding: 0rem 0.6rem;
    color: rgba(0, 128, 0, 0.759);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
    color: rgb(5, 118, 194);
    background-color: rgba(5, 118, 194, 0.137);

}


.reusable-thesis-item .random-thesis-suggestion-item-publish-date{
    line-height: 0;
}

.reusable-thesis-item .random-thesis-suggestion-item-author{
    // font-weight: bold;
    // line-height: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 0;
    gap: 0.5rem;
    // font-size: 0.8rem;
    margin: 0.5rem 0rem;
    // background-color: pink;
    color: rgb(66, 66, 66);

    @include media(mobile, tablet){
        flex-direction: column;
        gap: 0.35rem;
        margin: 0.7rem 0;
    }

}

.random-thesis-suggestion-item-author-icon-container{
    
    @include media(mobile, tablet){
        // background-color: pink;
        width: 100%;
    }
}

.reusable-thesis-item .random-thesis-suggestion-item-author-icon{
    background-color: rgba(232, 232, 232, 0.878);
    width: 1rem;
    height: 1rem;
    border-radius: 20%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    color: rgba(128, 128, 128, 0.582);
    line-height: 1rem;
    // display: none;
}

.reusable-thesis-item .random-thesis-suggestion-item-abstract{
    margin: 1rem 0rem;
    color: rgb(66, 66, 66);
}

.reusable-thesis-item .random-thesis-suggestion-item-keywords{
    // font-weight: bold;
    // line-height: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 0;
    gap: 0.5rem;
    color: gray;
    color: rgb(66, 66, 66);

    @include media(mobile, tablet){
        flex-direction: column;
        gap: 0.35rem;
        // width: 100%;
    }


}

.reusable-thesis-item .random-thesis-suggestion-item-keywords-icon-container{
    
    @include media(mobile, tablet){
        // flex-direction: column;
        // gap: 0.35rem;
        width: 100%;
    }

}

.reusable-thesis-item .random-thesis-suggestion-item-keywords-icon{
        background-color: rgba(232, 232, 232, 0.878);
    width: 1rem;
    height: 1rem;
    border-radius: 20%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    color: rgba(128, 128, 128, 0.562);
    line-height: 1rem;
    // background-color: red;

}


.random-thesis-suggestion-item-keywords-items{
    
    @include media(mobile, tablet){
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0.3rem;
    }
}

.random-thesis-suggestion-item-keywords-item{
    
    @include media(mobile, tablet){
        margin-bottom: 0.6rem;
        // background-color: pink;
        line-height: 1.2;
        // display: none;
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // gap: 0.3rem;
    }
}

.random-thesis-suggestion-item-supervisors-container{
 
    @include media(mobile, tablet){
        // background-color: pink;
        width: 100%;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
    }
}


.random-thesis-suggestion-item-supervisors-container-author{
 
    @include media(mobile, tablet){
        line-height: 1.5;
        // background-color: pink;
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        // gap: 0.5rem;
    }
}

.reusable-thesis-item .random-thesis-suggestion-item-supervisors{
    // font-size: 0.8rem;
    color: gray;
    cursor: pointer;

    @include media(mobile, tablet){
        line-height: 1.5;
        // background-color: pink;
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        // gap: 0.5rem;
    }
}

.reusable-thesis-item .random-thesis-suggestion-item-supervisors-info{
    display: flex;
    align-items: center;
    line-height: 1;
    // font-size: 0.8rem;
    color: gray;
    cursor: pointer;
}

.reusable-thesis-item .random-thesis-suggestion-item-options{
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    font-size: 0.7rem;
    flex-wrap: wrap;
}

.reusable-thesis-item .random-thesis-suggestion-item-option-item{
    padding: 0.65rem 1.2rem;
    background-color: pink;
    // border-radius: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 0;
    gap: 0.3rem;
    background-color: rgba(223, 210, 223, 0.469);
    background-color: rgba(233, 233, 233, 0.611);
    color: rgba(62, 62, 62, 0.918);
    // color: rgb(66, 66, 66);

}

.reusable-thesis-item .random-thesis-suggestion-item-option-item:hover{
    // padding: 0.5rem 1.1rem;
}

.reusable-thesis-item .rts-view{
    background-color: purple;
    background-color: #462e87;
    background-color: rgb(14, 142, 255);
    color: white;
    background-color: rgba(174, 243, 174, 0.692);
    color: rgba(0, 0, 0, 0.712);
    background-color: rgba(5, 118, 194, 0.212);

}

.reusable-thesis-item .random-thesis-suggestion-item-option-more{
    background-color: pink;
    // width: 1rem;
    // height: 1rem;
    padding: 0.3rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgb(223, 210, 223);
    background-color: rgba(220, 220, 220, 0.662);

}

.reusable-thesis-item .random-thesis-suggestion-item-option-more:hover{
    // padding: 0.4rem 0.8rem;
}
 