.previewServerDocumentPage{
    width: 100%;
    display: flex;
    justify-content: center;
}

.previewServerDocumentPage-inner{
    width: 90%;
    // background-color: skyblue;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // background-color: pink;
}