@import '../../../app.scss';

.saved-theses-library-page{
    display: flex;
    width: 100%;
    // background-color: yellow;
    justify-content: center;
    // min-height: 100%;
}


.saved-theses-library-page-inner{
    display: flex;
    width: 80%;
    flex-direction: column;
    // align-items: center;
    // background-color: lime;
    // justify-content: center;
    justify-content: space-between;
    min-height: calc(100vh - 4rem);
    margin-bottom: 1.5rem;

    @include media(mobile, tablet){
        width: 90%;
    }
}


.saved-theses-library-page .saved-theses-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.saved-theses-library-page .reusable-pagination-component{
    // padding: 1rem 0rem;
    // margin-top: 1rem;
    // background-color: yellow;

}

.saved-theses-library-page .adv-srp-pagination-component{
    // margin-top: 5rem;
    // background-color: orange;
}