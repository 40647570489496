.reusable-search-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: hotpink;
    background-color: white;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    padding: 0rem 0.1rem 0rem 0.5rem;
    // padding-top: 0.2rem;
    // padding-bottom: 0.2rem;
    // padding-left: 0.5rem;
    // padding-right: 0rem;
    gap: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}

.reusable-search-box-input{
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 0.7rem;
}

.reusable-search-box-input::placeholder{
    font-size: 0.7rem;
    font-weight: 300;
}

.reusable-search-box-input-container{
    height: 100%;
    width: 90%;
    // border: 1px solid black;
}


.reusable-search-box-sb{
    // position: absolute;
    // right: 0;
    // border: none;
    // outline: none;
    // height: 2rem;
    // width: 2rem;
    border-radius: 50%;
    // line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    // background-color: white;
    // background-color: plum;
    height: 100%;
    // width: 20%;
}

.reusable-search-box-sb > button {
    background-color: plum;
    color: white;
    background-color: rgb(64, 64, 64);
    // background-color: white;
    // height: 100%;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: white;
    background-color: rgb(5, 118, 194);

}