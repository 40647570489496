@import '../../../app.scss';

.logout-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.logout-page-inner{
    width: 85%;
    min-height: calc(80dvh - 5rem);
    // background-color: pink;
    display: flex;
    justify-content: center;
    align-items: center;
}


.logout-page-box{
    // width: 100%;
    // height: 50%;
    // background-color: lime;
    border: 1px solid #cac8c8;
    border:  1px solid rgba(5, 118, 194, 0.274);
    background-color: rgba(5, 118, 194, 0.021);

    display: flex;
    // justify-content: center;
    align-items: center;
    // justify-content: space-between;
    gap: 3rem;
    flex-direction: column;
    padding: 3rem;
    // padding-top: 2.5rem;
    // border-radius: 0.5rem;
    
}

.logout-title{
    font-weight: 300;
    color: rgb(5, 118, 194);

}

.logout-button-containers{

    display: flex;
    justify-content: space-around;
    // width: 90%;
    gap: 1rem;

    @include media(mobile, tablet){
        flex-direction: column;
    }
}

.logout-button-1{
    border: none;
    background-color: red;
    color: white;
    padding: 0.3rem 1rem;


}
.logout-button-2{
    border: none;
    background-color: tomato;
    color: white;
    padding: 0.3rem 1rem;
    
}
.logout-button-3{
    border: 1px solid gainsboro;
    // background-color: tomato;
    // color: white;
    padding: 0.3rem 1rem;

}








