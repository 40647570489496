.notifications-page{
    width: 100%;
    display: flex;
    justify-content: center;

}

.notifications-page-inner{
    width: 85%;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

}







.notification-item{
    border: 1px solid gainsboro;
    padding: 0.2rem 0.4rem;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

}

.notification-item-message{

}

.notification-item-add-info{

}


.notification-item-time{
    // background-color: yellow;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-weight: 300;
    font-size: 0.75rem;

}











