.admin-manage-accounts-page{
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin-manage-accounts-page-inner{
    width: 85%;
    display: flex;
    flex-direction: column;
    // background-color: lime;
}

.amap-title-block{
    width: 100%;
    background-color: beige;
    // padding: 1rem 1rem;
    margin-top: 1rem;
    // padding: 0rem 1rem;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.amap-reusable-title-block-inner{
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    // padding-right: 1rem;
    // background-color: yellow;
}

.amap-reusable-title-block-left{
    display: flex;
    align-items: center;
    gap: 1rem;

}

.amap-reusable-title-block-left-text{

}


.amap-reusable-title-block-left-icon{
    display: flex;
    align-items: center;
    line-height: 1rem;
    font-size: 1.5rem;
}

.amap-horizontal-scroll-menu{
    margin-top: 0.5rem;
    background-color: beige;
    // height: 5rem;
    // padding: 0.5rsem 0rem;
    // padding: 1rem 1rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.2rem;
    // background-color: yellow;s
    display: flex;
    align-items: flex-end;

}

.amap-search-functionality{
    display: flex;
    // align-items: center;
    // background-color: yellow;
    margin-top: 0.5rem;
}

.amap-search-functionality-inner{
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    // background-color: yellow;
}


.amap-search-functionality-inner .input-container{
    width: 40%;
//     background-color: pink;
// background-color: gold;s
padding: 0.2rem;
display: flex;
align-items: center;
}
.amap-search-functionality-inner .input-container-input{
    width: 100%;
    // background-color: pink;
    // padding: 0.2rem 0.3rem;
    // border-color: rgba(0, 0, 0, 0.295);
    border: 1px solid rgba(65, 65, 65, 0.315);
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    outline: none;
    display: flex;
    align-items: center;
    // height: 1.5rem;
    // background-color: lime;
    // margin: 0rem;
    height: 100%;
    padding: 0.1rem;
    padding-left: 0.7rem;
    display: flex;
    align-items: center;
    border: none;
    border-bottom: 1px solid gray;
}
.amap-search-functionality-inner .input-container-input::placeholder{
    font-size: 0.7rem;
}

.admin-manage-accounts-page .amap-search-functionality .search-by-select-span{
    padding: 0.2rem;
    // background-color: cyan;
    // border-color: rgba(85, 85, 85, 0.336);
    // // padding: 0.2rem 0.3rem;
    // background-color: pink;
    // margin: 0rem;
    // height: 1.5rem;
}

.admin-manage-accounts-page .amap-search-functionality .search-by-select{
    padding: 0.15rem;
    border-color: rgba(85, 85, 85, 0.336);
    // padding: 0.2rem 0.3rem;
    // background-color: pink;
    margin: 0rem;
    // height: 1.5rem;
    font-size: 0.7rem;
    outline: none;
    border: none;

}

.amap-search-functionality-inner .search-button{
    border: 1px solid rgba(65, 65, 65, 0.336);
    font-size: 0.8rem;
    border: none;
}

.amap-search-functionality-inner .exit-search-button{
    font-size: 0.8rem;
    // border: none;
}

.amap-pagination-component{
    position: fixed;
    bottom: 0;
    // background-color: pink;
    width: 100%;
    // max-width: 100vw;
    left: 0;
    padding: 0.5rem 0rem;
    display: flex;
    justify-content: center;

    // background-color: pink;
    align-items: center;
    background-color: white;
}

.amap-pagination-component-inner{
    width: 85%;
    // background-color: yellow;
    display: flex;
    align-items: center;
}

.admin-manage-accounts-page .sort-results-by-select{
    padding: 0.2rem;
    font-size: 0.7rem;
    border: none;
    outline: none;

}

.amappc-bottom-right{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.amappc-bottom-right-settings-icon-container{
    line-height: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}














.admin-manage-accounts-page .user-items{
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // align-items: center;
    // justify-content: center;
    font-size: 0.8rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
}
.reusable-user-item{
    width: 100%;
    border: 1px solid rgba(206, 206, 206, 0.774);
    border-top-left-radius: 0.5rem;
    
}

.reusable-user-item-inner{
    padding: 1rem;
    width: 100%;
}

.reusable-user-item-buttons{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.reusable-user-item-button{
    cursor: pointer;
    padding: 0.3rem 1rem;
    background-color: rgba(248, 233, 233, 0.486);
}






/////////////////////////////////
/// 
/// 

.amap-fullscreen-modal-body{
    width: 100%;
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    font-size: 0.8rem;
}

.amap-fullscreen-modal-body-inner{
    width: 85%;
    display: flex;
    flex-direction: column;
    // align-items: center;
}

.amap-fullscreen-modal-header{
    width: 100%;
    background-color: pink;
    display: flex;
    height: 4rem;
    justify-content: center;
    background-color: rgb(0, 183, 255);
}

.amap-fullscreen-modal-header-inner{
    width: 90%;
    // background-color: yellow;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

}

.amap-fullscreen-modal-header-inner .close{
    font-size: 1.3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    cursor: pointer;
}

.amap-fullscreen-modal-header-inner .title{
    font-size: 1.1rem;
}

.amap-fullscreen-modal-header-inner .update{
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    // font-weight: bold;

}
.amap-fullscreen-modal-body-form-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // background-color: pink;

}

.amap-fullscreen-modal-body .create-thesis-page-form-item{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    // cursor: ;
    // background-color: lime;
}

.amap-fullscreen-modal-body .create-thesis-page-form-item input {
    // display: flex;
    padding: 0.2rem;
    border: 1px solid gainsboro;
    // flex-direction: column;
    // cursor: ;
}

 .amap-fullscreen-modal-body .render-privilege-info-container{
    // background-color: pink;
    display: flex;
    align-items: center;
    line-height: 1rem;
 }

 .amap-fullscreen-modal-body .render-privilege-info{
    // background-color: pink;
    display: flex;
    align-items: center;
    line-height: 1rem;
    margin-left: 1rem;
    font-size: 1.1rem;
    color: gray;
    cursor: pointer;
 }

 .rendered-privilege-level-container{
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
 }

 .rendered-privilege-level-item{
    padding: 0.1rem 0.5rem;
    border: 1px solid gainsboro;
    display: flex;
    align-items: center;
    border-radius: 0.2rem;
    gap: 0.5rem;
    // display: flex;
 }








