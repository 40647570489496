.delete-thesis-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.delete-research-header{
    height: 5rem;
    // background-color: pink;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: rgba(231, 231, 231, 0.093);
    border-bottom: 1px solid rgb(234, 234, 241);

}

.delete-research-header-inner{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 300;
}

.delete-research-header-icon{
    line-height: 1rem;
    display: flex;
    align-items: center;

}

.delete-thesis-page-inner{
    width: 80%;
}

.delete-thesis-page-theses-list{
    width: 100%;
    // background-color: pink;
    margin-bottom: 1rem;
}


.delete-thesis-page-delete-button-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    // background-color: pink;
}

.delete-thesis-page-delete-button{
    background-color: gainsboro;
    color: crimson;
    border: none;
    border: 1px solid ghostwhite;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: crimson;
    color: white;

}

.delete-thesis-page-delete-button:hover {
    padding: 0.6rem 1.1rem;
    // background-color: crimson;
    // color: white;
}



////////////////////////
/// 
//////////////////////





.delete-thesis-modal{
    padding: 1rem;
    max-width: 100%;
    border-radius: 1rem;
    font-size: 0.9rem;
    font-weight: 300;
}

.delete-thesis-modal-inner{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.delete-thesis-modal-top{
    border-bottom: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0rem 0.5rem 0rem;
}

.delete-thesis-modal-top-title{

}

.delete-thesis-modal-top-close-icon{
    line-height: 1rem;
    display: flex;
    align-items: center;
    // background-color: pink;
    padding: 0.3rem;
    background-color: rgba(220, 220, 220, 0.333);
    cursor: pointer;
}

.delete-thesis-modal-middle-1{
    padding: 1.5rem 1rem;
    border: 1px solid gainsboro;
    background-color: ghostwhite;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    border-radius: 0.3rem;
    background-color: rgba(255, 166, 0, 0.255);

}

.delete-thesis-modal-middle-1-icon{
    line-height: 1rem;
    display: flex;
    align-items: center;
}

.delete-thesis-modal-middle-1-warning-text{

}

.delete-thesis-modal-bottom{
    border-top: 1px solid gainsboro;
}

.delete-thesis-modal-button-container{
    width: 100%;
    padding: 1rem 0rem;
}

.delete-thesis-modal-button{
    width: 100%;
    border: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    // color: white;
    // background-color: crimson;
}

.delete-thesis-modal-button:hover{
    color: white;
    background-color: crimson;
}

