@import '../../../app.scss';

.login-page{
    // display: none;
    height: 100vh;
    width: 100%;
    mix-blend-mode: color-burn;
    // background-color: yellow;
    // display: none;
    background-color: #382372;
    background: radial-gradient(circle at 100% 100%, #0265a7, #011f31);

    // background-color: aliceblue;    
}

.login-page-bg-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    // aspect-ratio: 13/2;
    // object-fit: contain;
    // mix-blend-mode: color-burn;
    // object-fit: fill;
    // object-fit: contain;
    // object-fit: scale-down;
    // object-fit: none;
    // object-fit: none;
    z-index: 1;
    position: absolute;

    // display: block;
    // // object-fit: contain;
    // max-width: 100%;
    // max-height: 100%;
    // width: auto;
    // height: auto;
    // display: none;
}

.login-page-form-container{
    width: 100%;
    height: 100%;
    // background-color: pink;
    z-index: 2;
    // position: absolute;
    //if there is an img use absolute

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background-color: #382372;

}

.login-page-form{
    // background-color: lime;
    // height: 80%;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    // align-items: flex-end;
    // justify-content: space-around;
    font-size: 0.85rem;
    // background-color: lightcoral;

    @include media(mobile, tablet){

        justify-content: center;
    }
}

.login-page-form-inner-wrapper{
    // background-color: yellow;
    background-color: white;
    // height: 85%;
    border-radius: 1rem;
    padding: 2.5rem 1.5rem;
    margin-right: 7rem;
    width: 45%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
    // background-color: #382372;
    // background-color: lime;

    @include media(mobile, tablet){
        // width: 100%;
        width: 90%;
        // background-color: lime;
        // background-color: orange;
        // background-color: white;
        padding: 0;
        margin: 0;
        padding: 2rem 0.5rem;
        // background-color: yellow;
    }

}

.login-page-form-inner-wrapper-group-1{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.login-page-form-inner-wrapper-group-1-a{
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0
}

.login-page-form-inner-wrapper-group-1-b{
    cursor: pointer;
}

.login-page-form-inner-wrapper-group-2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.login-page-form-inner-wrapper-group-2-a{

    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.5rem;
    // background-color: white;
    height: 2.5rem;
    position: relative;
    // border: 1px solid rgb(135, 134, 134);
    border: 1px solid rgba(135, 134, 134, 0.5);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // background-color: pink;
    border-radius: 0.2rem;


}

.login-page-form-inner-wrapper-group-2-a-icon-1{
    // position: absolute;
    bottom: 50%;
    top: 50%;
    left: 0.7rem;
    // background-color: pink;
    color: gray;
    flex: 1;
    margin-left: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.login-page-form-inner-wrapper-group-2-a-icon-2{
    // position: absolute;
    // top: 0;
    bottom: 50%;
    top: 50%;
    right: 0.7rem;
    // background-color: pink;
    color: gray;
    // display: none;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.1rem;

}


.login-page-form-inner-wrapper-group-2-a >input{
    all: unset;
    background-color: white;
    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.3rem 2rem;
    background-color: white;
    // background-color: #382372;

    // background-color: pink;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // line-height: 2rem;
    // width: 83%;
    height: 100%;
    flex: 15;
} 


.login-page-form-inner-wrapper-group-2-b{

    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.5rem;
    // background-color: white;
    height: 2.5rem;
    position: relative;
    // border: 1px solid rgb(135, 134, 134);
    border: 1px solid rgba(135, 134, 134, 0.5);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // background-color: pink;
    border-radius: 0.2rem;


}

.login-page-form-inner-wrapper-group-2-b-icon-1{
    // position: absolute;
    bottom: 50%;
    top: 50%;
    left: 0.7rem;
    color: gray;
    flex: 1;
    margin-left: 0.5rem;

    // background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    // display: none;
    // line-height: 3rem;
}

.login-page-form-inner-wrapper-group-2-b-icon-2{
    // position: absolute;
    // top: 0;
    bottom: 50%;
    top: 50%;
    right: 0.7rem;
    // background-color: pink;
    color: gray;
    // display: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 1.1rem;

}


.login-page-form-inner-wrapper-group-2-b >input{
    all: unset;
    background-color: white;
    // border: 1px solid rgb(135, 134, 134);
    // border-radius: 0.5rem;
    // padding: 0.3rem 2rem;
    background-color: white;
    // background-color: #382372;

    // background-color: pink;
    // line-height: 1rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // line-height: 2rem;
    // width: 83%;
    height: 100%;
    flex: 15;
} 

.login-page-form-inner-wrapper-group-2-c{
    cursor: pointer;
}

.login-page-form-inner-wrapper-group-3{
    width: 100%;
    background-color: pink;
}

.login-page-form-inner-wrapper-group-3 > button {
    border: none;
    background-color: black;
    color: white;
    padding: 0.7rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    background-color: #382372;
    background: linear-gradient(to right, #013152, #0d75b5)


}


