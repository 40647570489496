.app{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: blue;
  align-items: center;
  justify-content: center;
}













.app-cont{
  // max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-color: wheat;
  // align-items: center;
  // justify-content: center;
}
.topbar-in-appjs{
  z-index: 2;
  // background-color: yellowgreen;
  // height: 100vh;
  // display: none;
  height: 10vh;
  width: 100%;
}

.appjs-fixed-sidebar{
  // background-color: pink;
}

.appjs-body-below-topbar{
  display: flex;
}

.appjs-main-content{

  display: flex;
  // flex-direction: column;
  // align-items: center;
  // align-items: flex-end;
  // justify-content: center;
  width: 100%;
  // background-color: yellow;
}

.router-dom-LINK{
  text-decoration: none;
  color: inherit;
}


/* Add a custom scrollbar to the body element */
body::-webkit-scrollbar {
  width: 12px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #7d53ea, #ff6a88);
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #7d53ea, #ff6a88);
}




$breakpoints: (
  mobile : 620px, 
  tablet : 768px, 
  xl-tablet: 900px,
  small-desktop: 1000px,
  custom-825: 825px,
  1275px: 1275px,
  1015px: 1015px
);

//  mixin to print out media queries (based on map keys passed) 
@mixin media($keys...){
  @each $key in $keys { 
    @media (max-width: map-get($breakpoints, $key)){
      @content
    } 
  }
}

button {
  cursor: pointer;
}

.sample-className {
    padding-top: 130px;
    padding-bottom: 130px;
  
    //  pass the key(s) of the media queries you want to print  
    @include media(mobile, tablet, desktop){
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

//   @import "../../app.scss";



