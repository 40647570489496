@import '../../../app.scss';

.create-thesis-page{
    // background-color: #382372;
    // background-color: aliceblue;
    // height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-color: aquamarine;
    gap: 1rem;
}

.create-thesis-page .add-research-top{
    width: 100%;
    // background-color: pink;
    background-color: ghostwhite;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.22);
}

.create-thesis-page .add-research-top-inner{
    display: flex;
    align-items: center;
    // background-color: gainsboro;
    width: 75%;
    // padding: 0rem 4rem 0rem 0rem;
    gap: 1rem;
    line-height: 1rem;
}

.create-thesis-page .add-research-top-inner-icon-container{
    font-size: 2rem;
    border: 2px solid rgb(55, 54, 54);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(53, 53, 53);
    // font-weight: 100;

    @include media(mobile, tablet){
        font-size: 1.5rem;
    }
    
}

.create-thesis-page .add-research-top-inner-icon{

}

.create-thesis-page .add-research-top-inner-text{
    font-size: 1.1rem;
}

.create-thesis-page  .create-thesis-page-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    // background-color: yellow;
    
    // overflow: hidden;
    // position: relative;
}

.create-thesis-page  .create-thesis-topbar{
    width: 100%;
    // background-color: pink;
    text-align: center;
    // background-color: gainsboro;
    background-color: #382372;
    color: white;
    font-weight: 100;
    position: sticky;
    top: 0;
}

.create-thesis-page  .create-thesis-page-form-sections{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    width: 80%;
    // background-color: lime;
}

.create-thesis-page  .form-part-1{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form-part-1-choose-option{
    // text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.create-thesis-page .form-part-1-choose-option-head{
    // text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
}

.create-thesis-page .form-part-1-choose-option-body{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 0.75rem;
    flex-direction: column;
    // color: #a2a2a2;
}

.form-part-1-choose-option-item{
    display: flex;
}

.form-part-1-choose-option-item-inner{
    // background-color: pink;
    flex: 1;
    border: 1px solid black;
    // border-radius: 0.5rem;
    padding: 0.2rem 1rem;
    cursor: pointer;
    text-align: center;
    width: 80%;
    display: flex;
    align-items: center;
    // justify-content: center
}

.create-thesis-page  .create-thesis-page-form-item{
    //label
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.create-thesis-page-form-item-textarea{
    border: 1px solid rgb(214, 212, 212);
    border-radius: 0.4rem;
    padding: 1rem;
}

.create-thesis-page  .create-thesis-page-form-item  label {
    font-size: 0.8rem;
    font-weight: 100;
    // padding-left: 1rem;
    font-weight: bold;
}

.create-thesis-page  .create-thesis-page-input{
    padding: 0.5rem ;
    border-radius: 0.3rem;
    border: 1px solid rgb(209, 206, 206);
    // border: none;
    // border-bottom: 1px solid black;
}

.create-thesis-page-category{
    margin-top: 0.3rem;
}

.create-thesis-page-category-inner{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.create-thesis-page-category-inner-item{
    border: 1px solid rgba(128, 128, 128, 0.404);
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
    color: rgb(61, 61, 61);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    // line-height: 1rem;
    cursor: pointer;
    // border-color: skyblue;
    color: rgb(4, 125, 173);
}

.create-thesis-page-category-inner-item.active{
    // background-color: gray;
    background-color: rgb(202, 238, 253);
}

.ctpcii-span{
    display: flex;
    align-items: center;
    line-height: 1rem;
}

.create-thesis-page  select{
    padding: 0.5rem ;
    border-radius: 0.3rem;
    border: 1px solid rgb(209, 206, 206);
    // border: none;
    // border-bottom: 1px solid black;
}

.create-thesis-page  .form-part-2{
    // height: 50vh;
    // background-color: lime;
    // margin-top: 1rem;
    width: 100%;

    overflow: hidden;
    position: relative;

}
.create-thesis-page  .quill-content-section{
    // display: none;
    // max-height: 100vh;
    // overflow-y: scroll;
    // min-height: 30vh;
    // height: 100vh;
    // background-color: pink;
    width: 100%;

    overflow: hidden;
    position: relative;

}

.create-thesis-page  .quill-content-section-label{
    font-weight: bold;
    // padding-left: 1rem;
    font-size: 0.8rem;
}

.create-thesis-page  .quill{
    height: 100%;
    width: 100%;
}

.create-thesis-page  .ql-container{
    // height: 100vh;
}

.create-thesis-page  .ql-container.ql-snow{
    min-height: 30vh;
    // min-height: 20rem;
    // display: none;
}

.create-thesis-page  .ql-toolbar.ql-snow{
    background-color: #ece8f7;
    // display: none;
    // position: absolute;
    // position: sticky;
    // position: fixed;
    // z-index: 99999999999999;
    // background-color: pink;
    // top: 0;
    // height: auto;
    height: 4rem;
    width: 100%;
    // width: fit-content;

}

.create-thesis-page  .ql-editor{
    // background-color: beige;
    background-color: white;
    // background-color: black;

}

.create-thesis-page  .quill-toolbar-fixed{
    // position: absolute;
    position: fixed;
    top: 0;
    z-index: 3;
    height: 4rem;
    left: 0;
    // width: 95%;

    // width: 80%;
    // overflow: hidden;
    // position: relative;
}



.create-thesis-page  .form-part-3.keyword-section{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    // background-color: pink;

    @include media(mobile){
        // flex-direction: column;
        margin-bottom: 1.5rem;
        // flex-wrap: wrap;
    }
}

.create-thesis-page  .keyword-section-label{
    font-weight: bold;
    // padding-left: 1rem;
    font-size: 0.8rem;
}

.create-thesis-page  .keyword-section-input{
    width: 100%;
    display: flex;

    @include media(mobile){
        flex-direction: column;
        gap: 0.5rem;
        // flex-wrap: wrap;
    }
}

.create-thesis-page  .keyword-section-input-box{
    border: 1px solid rgb(207, 201, 201);
    border-right: 0px;
    min-width: 40%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    
    @include media(mobile){
        width: 90%;
        border-right: 1px solid rgb(207, 201, 201);
        // padding: 0;
    }
}

.create-thesis-page  .keyword-section-input-box::placeholder{
    font-size: 0.75rem;
}

.create-thesis-page  .keyword-section-button{
    min-width: 10%;
    // max-width: 50%;
    // background-color: plum;
    background-color: #d9cef8;
    background-color: #cec4eb69;
    color: black;
    border: none;
    // border-top-right-radius: 0.2rem;
    // border-bottom-right-radius: 0.2rem;
    padding: 0.3rem 1rem;
}

.create-thesis-page  .current-keywords{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // align-items: center;
    // justify-content: flex-end;
    margin-top: 0.5rem;
    // background-color: lime;
}

.create-thesis-page  .keyword-item{
    // border-radius: 0.1rem;
    // border: 0.1rem solid rgb(185, 179, 179);
    padding: 0rem 0.7rem;
    border-radius: 0.3rem;
    // background-color: #482d94;
    // background-color: #714dd2;
    // background-color: rgb(218, 188, 218);
    // background-color: gainsboro;
    // color: black;

    font-weight: 300;
    // color: rgb(0, 0, 0);
    color: rgb(0, 80, 112);
    font-size: 0.8rem;
    // background-color: red;
    // background-color: rgb(237, 234, 234);
    // color: black;
    // border: 1px solid black;
    // background-color: black;
    // display: none;
}

.create-thesis-page  .keyword-items{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.create-thesis-page  .keyword-items-wrapper{
    display: flex;
    gap: 1rem;
    align-items: center;
    line-height: 1rem;
    border: 1px solid rgba(2, 165, 165, 0.479);
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    // background-color: rgba(248, 248, 255, 0.048);
    // background-color: rgba(202, 238, 253, 0.212);


    
}

.create-thesis-page  .keyword-item-delete{
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1rem;
    color: crimson;
    color: rgb(2, 98, 153)
    // color: red;
}

.create-thesis-page  .form-part-4-button-create-thesis{
    width: 100%;
    margin-top: 1rem;
    // background-color: red;
}

.create-thesis-page  .form-part-4-button-create-thesis button:disabled{
    background-color: tomato;
}

.create-thesis-page  .button-create-thesis{
    width: 100%;
    border: none;
    padding: 1rem 0.2rem; 
    color: white;
    background-color: #382372;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 0.4rem;
}

.create-thesis-modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    // background-color: pink;
    background-color: rgba(255, 192, 203, 0.449);
    z-index: 99999999999;
    // position: fixed;
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.create-thesis-modal{
    // opacity: 1;
    // z-index: 99999999999;
    // background-color: white;
    // width: 90vw;
    // display: flex;
    // width: 100vw;
    // height: 100vh;
    // background-color: lime;
    // position: fixed;
    // z-index: 99999999999999999;
    // top: 0;
    
}





//create document component
//create document component
//create document component
//create document component
//create document component
.create-thesis-add-document-component{
    // width: 100%;
    // min-height: 100%;
}

.create-thesis-add-document-component .create-thesis-add-document-component-wrapper{
    // width: 100%;
    // min-height: 100%;
}



.create-thesis-add-document-component .add-research-top-inner-icon-container{
    font-size: 2rem;
    border: 2px solid rgb(55, 54, 54);
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(53, 53, 53);
    // font-weight: 100;
    
}

.create-thesis-add-document-component .add-research-top-inner-icon{

}

.create-thesis-add-document-component .add-research-top-inner-text{
    font-size: 1.2rem;
}

.create-thesis-add-document-component .add-research-middle{
    // background-color: yellow;
    // height: 100vh;
    display: flex;
    justify-content: center;
    // background-color: gainsboro;
    background-color: whitesmoke;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.create-thesis-add-document-component .add-research-middle-inner{
    background-color: burlywood;
    background-color: white;
    width: 60%;
    height: max-content;
    padding: 2rem;
    margin: 1rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.create-thesis-add-document-component .add-research-middle-inner-prompt{
    // font-weight: bold;
    font-weight: 400;
    font-size: 1rem;
}

.create-thesis-add-document-component .add-research-middle-inner-select-file{
    border: 1px solid blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    color: blue;
    display: flex;
    gap: 0.5rem;
    line-height: 1rem;
    // background-color: pink;
}

.create-thesis-add-document-component .add-research-middle-inner-select-file:hover{
    background-color: blue;
    color: white;
}

.create-thesis-add-document-component .add-research-middle-inner-select-file-icon-container{
    font-size: 2rem;
}

.create-thesis-add-document-component .add-research-middle-inner-skip-section{
    border-top: 1px solid rgba(107, 107, 107, 0.517);
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 0.5rem;
    // align-items: center;
}



.create-thesis-add-document-component .add-research-middle-inner-skip-section-right{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.create-thesis-add-document-component .add-research-middle-inner-skip-section-right-skip{
    color: rgb(34, 34, 239);
    cursor: pointer;
}

.create-thesis-add-document-component .create-thesis-add-document-component .add-research-middle-inner-skip-section-right-skip:hover{
    text-decoration: underline;
}

.create-thesis-add-document-component .add-research-middle-inner-skip-section-right-go{
    background-color: blue;
    color: white;
    padding: 0.2rem 1.2rem;
    border-radius: 1rem;
}




//&& condition
.create-thesis-add-document-component .add-research-uploaded-document{
    border: 1px solid rgba(117, 117, 117, 0.251);
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
}

.add-research-uploaded-document-left{
    width: 10%;
    // background-color: pink;
}

.create-thesis-add-document-component .add-research-uploaded-document-left-icon-container{
}

.create-thesis-add-document-component .add-research-uploaded-document-left-icon-container-icon{
    all: unset;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    color: rgb(107, 104, 104);
}

.add-research-uploaded-document-right{
    width: 80%;
}


.add-research-uploaded-document-rt-name{

    font-weight: bold;
    font-size: 0.9rem;
    width: 100%;

    height: max-content;
    display: flex;
    flex-wrap: wrap;
    // background-color: lime;
    // display: flex;
    // flex-wrap: nowrap;
    // align-items: center;
    // height: 1rem;
    // overflow: hidden;
}







