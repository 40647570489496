.test{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: pink;
}

.test-inner{
    width: 50%;
    height: 50%;
}