@import '../../app.scss';

.image-ocr-extract-page{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}

.image-ocr-extract-page-wrapper{
  width: 80%;
  // background-color: yellow;
}

.image-ocr-extract-page-ocr-image-wrapper{
  // display: flex;
  // padding: 0.5rem;
  // position: fixed;
  // z-index: 3;
  width: 100%;
  // width: 100%;
  // padding: 0rem 1rem;
  // width: 100vw;
  display: flex;
  justify-content: center;
  // justify-content: flex-start;
  background-color: white;
  // background-color: greenyellow;
  margin-top: 1rem;
  position: relative;
}


.image-ocr-extract-page-ocr-image-container{
  display: flex;

  // max-width: 100%;
  width: 90%;
  max-height: 50vh;
  height: 50vh;
  margin-bottom: 1rem;
  overflow-y: scroll;
  position: relative;
  background-color: rgb(239, 239, 239);
  border-radius: 0.5rem;
}

.image-ocr-extract-page-ocr-image-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.image-ocr-extract-page-ocr-image-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.image-ocr-extract-page-ocr-image-container::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #03010923, #3e3e3ec2);
  border-radius: 10px;
}

/* Handle on hover */
.image-ocr-extract-page-ocr-image-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #03010923, #3e3e3ec2);
}


.image-ocr-extract-page-ocr-image-wrapper-left-icon{
  // height: 100%;
  // background-color: pink;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // position: absolute;
  // left: 0;
  font-size: 3rem;
  cursor: pointer;
  width: 5%;
  // background-color: pink;
}

.image-ocr-extract-page-ocr-image-wrapper-left-icon .ocr-left-icon{
  // background-color: lime;
  font-size: 2rem;
}


.image-ocr-extract-page-ocr-image-wrapper-right-icon{
  // height: 100%;
  // background-color: pink;
  display: flex;
  align-items: center;
  // position: absolute;
  // right: 0;
  font-size: 3rem;
  // background-color: yellow;
  cursor: pointer;

  width: 5%;
  // background-color: pink;
}

.image-ocr-extract-page-ocr-image-wrapper-right-icon .ocr-right-icon{
  // background-color: lime;
  font-size: 2rem;
}





.image-ocr-extract-page-ocr-image-container-image{
  width: 100%;
  // height: 1000%;
  height: max-content;
  object-fit: contain;
  // object-fit: fill;
  object-fit: cover;
  overflow: scroll;
  // display: flex;
  // justify-content: center;
  cursor: pointer;
  // cursor:;
}

.image-to-extract-icon{
  font-size: 3rem;
  // padding-left: ;
  // background-color: pink;
}

.delete-image-icon-container{
  position: absolute;
  right: 0;
  color: gray;
  background-color: ghostwhite;
  width: 1rem;
  height: 1rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.delete-image-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  color: red;

}


.image-ocr-extract-page-ocrText{
  // margin-top: calc(50vh + 2rem);
  width: 100%;
}

.quill-content-section{
  width: 100%;
  // background-color: red;
}

.image-ocr-extract-page .quill-content-section-label{
  // padding-left: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  // background-color: pink;
}

.quill-content-section-label span{
  width: 90%;
  display: flex;
  justify-content: center;
}

.image-ocr-extract-page .ql-container.ql-snow{
  min-height: 20vh;
  z-index: 0;
}

.image-ocr-extract-page .quill-toolbar-fixed{
  // all: unset;
  position: fixed;
  // top: calc(50vh + 1rem);
  top: 4rem;
  background-color: white;
  opacity: 1;
  z-index: 2;
  width: 100%;
  width: max-content;
  // background-color: red;
}

.image-ocr-extract-page .ql-editor{
  // display: none;
  overflow: auto;
}


.image-ocr-extract-page-bottom-buttons{
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  @include media(mobile, tablet){
    flex-direction: column;
  }
}

.ocr-files-upload-input-container{
  display: flex;
  align-items: center;
  gap: 1rem;

  @include media(mobile, tablet){
    // flex-direction: column;
    // background-color: red;
  }
}

.image-ocr-extract-page .image-ocr-extract-page-input{
  // padding: 2rem;
  border-radius: 0.2rem;
  background-color: rgb(228, 228, 228);
  display: none;
  // background-color: yellow;

}

.image-ocr-extract-page-submit-button{
  padding: 0.35rem;
  border-radius: 0.2rem;
  border: 1px solid black;
  background-color: rgba(220, 220, 220, 0.289);
  cursor: pointer;
  border: none;
  border: 1px solid rgba(164, 164, 164, 0.391);
  font-size: 0.8rem;
}

.image-ocr-extract-page-submit-button .start-extr{
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 2rem;
  // display: none;

  @include media(mobile, tablet){
    // background-color: pink;
    margin-left: 0rem;
  }
}

.image-ocr-extract-page-submit-button.clear-all-files{
  border: none;
}

.extraction-progress-div{
  display: flex;
  gap: 1rem;
  align-items: center;

  @include media(mobile, tablet){
    // background-color: pink;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.extract-ocr-copy-text{
  border: none;
  color: gray;
  color: white;
  background-color: black;
  float: right;
  border-radius: 0.3rem;
  padding: 0.2rem 0.5rem;
  width: 100%;
  padding: 1rem 0rem;
  cursor: pointer;
  margin-bottom: 1rem;
//   position: absolute;
//   right: 0;
}


.spin {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.7rem solid rgb(226, 211, 211);
  border-radius: 50%;
  border-top-color: yellow;
  border-top-color: blue;
  animation: spin 1s linear infinite;
  /* -webkit-animation: spin 0.5s ease-in-out infinite; */
  /* background-color: pink; */
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

