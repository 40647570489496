.advanced-search-results-page{
    width: 100%;
    // background-color: pink;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    min-height: 100%;
    // height: calc(100% + 4rem);
}

.advanced-search-results-page-wrapper{
    width: 90%;
    // overflow: hidden;
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 5rem);
    // justify-content: space-between;
    height: calc(100% + 4rem);
 
}

.advanced-search-results-page-wrapper-filters{
    // padding: 0rem 1rem;
    // background-color: cyan;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.advanced-results-page-results{

}

.advanced-results-page-results-inner{


}

.advanced-results-page-items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // background-color: pink;
}


.render-filter-parameters{
    display: flex;
    // background-color: lime;
    // flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: flex-start;
    // justify-content: flex-end;

}

.render-filter-parameters-inner{
    display: flex;
    // gap: 2rem;
    // background-color: pink;
    // flex-direction: column;
    // padding: 0.5rem;
    // display: none;
    // height: 4rem;
    // width: 100%;
}

.render-filter-parameter-item-div{
    display: flex;
    align-items: center;
    line-height: 1rem;
    // background-color: gold;
    // font-size: 0.3rem;
}

.render-filter-parameters button {
    background-color: white;
    border-radius: 1rem;
    border: 1px solid rgba(128, 128, 128, 0.216);
    // padding: 0rem 0.5rem;
    font-size: 0.75rem;
    cursor: auto;

}

.render-filter-parameter-item-icon{
    font-size: 1rem;
    color: red;
    cursor: pointer;
}

.adv-srp-pagination-component{
    position: fixed;
    // padding: 1rem 0rem;
    bottom: 0;
    right: 0;
    padding: 0.5rem 0rem;
    background-color: white;
    display: flex;
    justify-content: center;
    gap: 2rem;

    // background-color: pink;
    // justify-content: flex-start;
    align-items: flex-start;
}