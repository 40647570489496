.admin-homepage{
    display: flex;
    justify-content: center;
    width: 100%;
    // background-color: yellow;
}

.admin-homepage-inner{
    display: flex;
    flex-direction: column;
    width: 85%;
    // background-color: gold;
    min-height: calc(100vh - 4rem);

}

.admin-homepage-top-info{
    margin-top: 1rem;
    // background-color: pink;
    // background-color: lime;

}

.admin-homepage-links{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;

}

.admin-homepage-link{
    // background-color: yellow;
    background-color: beige;
    padding: 0.5rem 0rem;

}

.admin-homepage-link-inner{
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: 1rem;
}






////////////modal for admin home page
.modal-contact-admin-component{
    // background-color: yellow;
    // background-color: lime;
    width: 100%;
    // height: 100%;
}

.contact-admin-component-inner{
    width: 100%;

}

.modal-contact-admin-component .caci-topbar{
    margin-bottom: 1rem;
}

.contact-admin-component-form{
    // background-color: pink;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-admin-component-title-wrapper{
    width: 100%;
}

.contact-admin-component-title{
    width: 100%;
}

.contact-admin-component-email-wrapper{
    width: 100%;

}

.contact-admin-component-email{
    width: 100%;

}

.contact-admin-component-textarea-wrapper{
    width: 100%;

}

.contact-admin-component-textarea{
    width: 100%;

}

.contact-admin-component-button-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.contact-admin-component-button{
    border: none;
    padding: 0.5rem 2rem;
    
}


