.amcp-add-new-category{
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    // background-color: pink;
}


.amcp-add-new-category button{
    border: none;
    
}

.amcp-add-new-category span{
    line-height: 1rem;
    display: flex;
    align-items: center;
    // border: none;
    cursor: pointer;
    
}


.user-items-amcp{
    margin-top: 0rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    // align-items: center;
    // justify-content: center;
    font-size: 0.8rem;
    margin-bottom: 3rem;
}






