
// .homepage .homepage-paginationComponent-retainer{
//     margin-bottom: 1rem;
//     margin-top: 1rem;
//     // background-color: rgba(255, 192, 203, 0.494);
//     // color: red;
//     height: 2rem;
//     // display: none;
//     background-color: red;
// } 

@import '../../../app.scss';

.pagination-bottom-bar-component{
    width: 100%;
    // margin-bottom: 10rem;
    // background-color: lime;

    @include media(mobile, tablet){
        // padding-top: 2rem;
    }


}

.pagination-bottom-bar-component-inner{
    display: flex;
    gap: 1.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    justify-content: flex-start;

    // background-color: yellow;
    // height: 2rem;

    @include media(mobile, tablet){
        // padding-top: 2rem;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.pagination-bottom-bar-component-inner-button-left{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem;
    background-color: ghostwhite;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 0;
    line-height: 1rem;

}

.pagination-bottom-bar-component-inner-button-right{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.3rem;
    // background-color: aqua;
    background-color: ghostwhite;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    line-height: 1rem;
}

.pagination-component-pages{
    display: flex;
    gap: 1rem;
    align-items: center;
    // background-color: aqua;

}

.pagination-component-pages button {
    border: none;

    display: flex;
    align-items: center;
    padding: 0.8rem;
    background-color: ghostwhite;
    // width: 0.5rem;
    // height: 0.5rem;
    width: 1rem;
    height: 1rem;
    
    justify-content: center;
    border-radius: 50%;
    // font-size: 0.8rem;
    // line-height: 1rem;
    // border-spacing: 0rem;
    // background-color: greenyellow;
    line-height: 1rem;

    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.466);
}

.page-number{
    // background-color: red;

}

.page-number.current{
    background-color: black;
    color: white;
}