//STRICT CLASS NOT ENFORCED THROUGHOUT THE WHOLE PAGE

.homepage{
    // background-color: pink;
    // background-color: lime;
    width: 100%;
    // height: 100vh;
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 0.8rem;
}

.homepage .homepage-wrapper{
    width: 95%;
    // background-color: gray;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // height: auto;
}

.homepage .homepage-wrapper-section1{
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // align-items: center;
    width: 100%;
}

.homepage .homepage-wrapper-section1-h1{
    width: 100%;
    text-align: center;
    font-weight: 400;
}

.homepage .homepage-wrapper-section1-search{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: lime;
    gap: 2rem;
}

.homepage .homepage-wrapper-section1-input-container{
    width: 60%;
    position: relative;
}

.homepage .homepage-wrapper-section1-input{
    padding: 0.8rem;
    border-radius: 0.7rem;
    width: 100%;
    // border: 1px solid rgba(1, 77, 163, 0.388);
    border: 1px solid rgba(0, 100, 167, 0.548);
    // background-color: #382372;
}

.homepage .homepage-wrapper-section1-input::placeholder{
    font-size: 0.75rem;
}

.homepage .use-advanced-search{
    // background-color: purple;
    // background-color: #382372;
    color: white;
    color: rgb(1, 82, 114);
    // color: rgb(0, 183, 255);
    cursor: pointer;
    position: absolute;
    right: 0;
}

.homepage .homepage-wrapper-section1-button-container{
    // background-color: yellow;
}

.homepage .homepage-wrapper-section1-button{
    border: none;
    // background-color: purple;
    // background-color: #382372;
    // background-color: rgb(2, 63, 87);
    // background-color: blue($color: #000000)
    background-color: rgb(5, 118, 194);


    color: white;
    padding: 0.5rem;
    border-radius: 0.2rem;
    height: 80%;
    margin-left: 0.5rem;
}

.homepage .homepage-wrapper-section2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    line-height: 0;
    cursor: pointer;
}

.homepage .homepage-wrapper-section2-icon{
    font-size: 1.5rem;
    margin-right: 0.2rem;
    // color: cyan;
    // color: purple;
    color: rgb(0, 81, 148);
}


.homepage-wrapper-random-suggestions{
    margin-top: 1rem;
}

.homepage-wrapper-random-suggestions-wrapper-items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}






///////////////////////////
/// 